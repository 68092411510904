import { Box, Button, Heading } from '@chakra-ui/react';
import Head from 'next/head';
import Link from 'next/link';

function Home() {
  return (
    <>
      <Head>
        <title>SIGUE | Follow to Earn</title>
      </Head>
      <Box py={16}>
        <Box
          maxW="48rem"
          mb={10}
          sx={{
            position: 'relative',
            zIndex: 1,
            flex: 1,
          }}>
          <Heading as="h1" fontSize="7vw" mb={4} fontWeight={900} color="primary.700" whiteSpace="nowrap">
            Follow to earn.
          </Heading>
          <Heading fontSize="max(1.8vw, 1.5rem)" mb={6} fontWeight={800} color="primary.700" opacity={0.8}>
            We help you discover and get discovered. <br />
            Earn SIGUE by following. <br />
            Use SIGUE to get followers.
          </Heading>
        </Box>

        <Button size="lg" colorScheme="primary" mr={6} shadow="lg" as={Link} href="/follow">
          Follow to earn
        </Button>

        <Button size="lg" colorScheme="primary" variant="outline" as={Link} href="/stake">
          Get followers
        </Button>
      </Box>
    </>
  );
}

export default Home;
